import React, { Component } from 'react'
import Layout from '../components/layout'

export default class IndexPage extends Component {
  render() {
    return (
      <Layout>
        <section className="ss-Me">
          <div className="ss-AppLayout">
            <div className="css-typing">
              <p>A man of ordinary talent will always be ordinary,</p>
              <p>whether he travels or not;</p>
              <p>but a man of superior talent will go to pieces</p>
              <p>if he remains forever in the same place.</p>
              <p>- "Mozart"</p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
